// Gatsby Doc - https://www.gatsbyjs.com/docs/how-to/styling/using-web-fonts/#self-host-google-fonts-with-fontsource
import "@fontsource/lato/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/700.css"

// import './src/styles/global.css' / includes tailwind, needed for future migration from bootstrap!

require('./src/scss/styles.scss');
require('./src/styles/fullpage.css');

